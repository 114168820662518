
import {useState, useEffect} from 'react'
import { Navbar } from './Navbar'
import AOS from 'aos';
import 'aos/dist/aos.css';
import data from '../data/projects.json'
import pic4 from '../img/projectimg/scouts.webp'
import pic3 from '../img/projectimg/jhd.webp'
import pic5 from '../img/projectimg/sporza.webp'
import pic2 from '../img/projectimg/cdl.webp'
import pic1 from '../img/projectimg/gentevt.webp'
import pic6 from '../img/projectimg/ec.webp'
import pic7 from '../img/projectimg/bob.webp'
import pic8 from '../img/projectimg/isy.webp'
import pic9 from '../img/projectimg/gerania.webp'
import pic10 from '../img/projectimg/scoutsweb.webp'
import { Footer } from './Footer'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

//   Modal.setAppElement('#Projects');
// import Scouts from '../img/projectimg/scouts.png'



const Projects = () => {
  
    let subtitle;
    const [category, setCategory] = useState('All')
    const [projects, setProjects] = useState(data["projects"])
    const [modalIsOpen, setIsOpen] = useState(false);
    const [info, setInfo] = useState("");

    function openModal(project) {
        setIsOpen(true);
        setInfo(project);
      }
    
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
      }
    
      function closeModal() {
        setIsOpen(false);
      }
    

    useEffect(() => {
        AOS.init({ duration: 1000 });
        window.scrollTo(0, 0)
      }, []);
      const pic = [pic1, pic1, pic2, pic3,pic4,pic5,pic6,pic7,pic8,pic9,pic10]
    return (
        <div>
             <Helmet prioritizeSeoTags>
            <title>bocky. | Projects</title>
                <meta
                name="description"
                content="bocky. Our projects that we have build."
                />
                <meta name="author" content="Casper De Bock" />
                    <meta name="keywords" content="bocky,modern websites,websites,bocky.,web design, projects" />
                    <meta name="title" content="bocky. | Projects" />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://bocky.be/projects" />
                    <meta property="og:title" content="bocky." />
                    <meta property="og:image" content="../../../public/bocky.png" />
            </Helmet>
            <Navbar/>
            <main>
                <section className='projectsSection' >
                <div className='mission big'>Projects</div>
                <div className='infocont'>
                    <div className='infoProjects'>
                        By implementing cutting-edge design strategies, seamless user experiences, and innovative digital solutions in our projects, 
                        we ensure that our clients not only achieve a more modern and professional online presence, 
                        but also attract and engage a wider customer base in the ever-evolving digital world.
                    </div>
                </div>
                <div className='projectsContain'>
                    <div className='navContain'  >
                        <div className="navProjects">
                            <div onClick={() => setCategory("All")} className={category === "All" ? "navItem bord activeNavItem" :"navItem bord" }>All</div>
                            <div onClick={() =>setCategory("Design")} className={category === "Design" ? "navItem bord activeNavItem" :"navItem bord" }>Design</div>
                            <div onClick={() =>setCategory("Web")} className={category === "Web" ? "navItem bord activeNavItem" :"navItem bord" }>Web</div>
                            <div onClick={() =>setCategory("App")} className={category === "App" ? "navItem bord activeNavItem" :"navItem " }>Mobile</div>
                        </div>
                    </div>
                    <div className='projectsBlokken' data-aos="fade-up">
                        {projects.filter((project) => {
                           
                            if (category === 'All') {
                                return project
                            } else {
                                return project.category === category
                            }
                        }).map((project, index) => {
                            const url = `../img/projectimg/${project.img}`
                           
                            return (
                                <>
                                    {/* <div onClick={()=>openModal(project)} key={project.id}  className='projectBlok'>
                                    
                                            <img src={pic[project.id]} alt='imgproject' className='blokImg'/>
                                  
                                        <div className='blokInfo'>
                                            <div className='blokCat'>{project.category}</div>
                                            <div className='blokTitle'>{project.name}</div>
                                    
                                        </div>
                                    </div> */}
                                    <Link data-aos="fade-up" to={`/projects/${project.linkname}`} className='projectWrapper'>
                                        <img src={pic[project.id]} alt={project.blog[0].articles[0].altTag} className='projectImgwrap'/>
                                        <div className='projectInfo'>
                                        <div className='highlight'>{project.category}</div>
                                            <div className='projectTitle'>{project.name}</div>
                                            <div className='projectDesc'>{project.description}</div>
                                            <div className='readmore'>Read more</div>
                                        </div>
                                    </Link>
                                </>
                            )
                        })}
                    </div>
                </div>
                </section>
            </main>
            <div>
            {/* <Modal
            isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                className="modal"
            >
            <div className='upperModal'>
            <h2 className='titleModal'>{info.name}</h2>
            <button className='closeBtn' onClick={closeModal}><GrClose/></button>
            </div>
            <div className='infoProjectModal'>
                <div className='leftInfoProjectModal'>
                    <div className='subtitle'>
                        Description:
                    </div>
                    <div className='text'>
                        {info.description}
                    </div>
                    <div className='subtitle top'>
                        Category:
                    </div>
                    <div className='text bottom'>
                        {info.category}
                    </div>
                    <div className='subtitle top btnInfoModal'>
                    {info.linkfromweb === ""? "":
                    <Link rel="noreferrer" target={'_blank'} to={info.linkfromweb} className='checkbtnModal ' >
                     Check it out
                   </Link>
                        }
                    </div>
                  
            </div>
            <div className='rightInfoProjectModal'>
                    <img src={pic[info.id]} alt='imgproject' className='imgModal'/>
            </div>

            </div>
                        
                </Modal> */}
           
    </div>
            <Footer type="none"/>
        </div>
    )
}

export default Projects