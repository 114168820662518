
import {useState, useEffect} from 'react'
import { Navbar } from './Navbar'
import { Footer } from './Footer'
import { Helmet } from 'react-helmet-async'
import AOS from 'aos';
import 'aos/dist/aos.css';
import data from '../data/projects.json'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom'
import {BsInstagram, BsDiscord, BsTwitter} from 'react-icons/bs'
import { NotFound } from './NotFound';

const Detail = () => {
    const { linkname } = useParams();
    const [blogData, setBlogData] = useState(null)
    const [loader, setLoader] = useState(true)
    const [loadingImage , setLoadingImage] = useState(true)
   
    // console.log(linkname)

    useEffect(() => {
        AOS.init({ duration: 1000 });
        window.scrollTo(0, 0)
      }, []);

      useEffect(() => {
        setLoader(true);
        setLoadingImage(true);
        const project = data.projects.find((project) => project.linkname === linkname);
        console.log(project)
        if (project) {
          setBlogData(project);
        }
        setLoader(false);
      }, [linkname]);
    
      useEffect(() => {
        setLoadingImage(false);
      }, [blogData]);
    
      // Conditional rendering when blogData is null
      if (blogData === null) {
        console.log('null')
        return (
          <>
            <NotFound />
          </>
        );
      }
    
      else{
        return (
            <>
             <Helmet prioritizeSeoTags>
            <title>bocky. | {blogData.name}</title>
                <meta
                name="description"
                content="bocky. Check how we made this project and what impact it had on the company."
                />
                <meta name="author" content="Casper De Bock" />
                    <meta name="keywords" content={blogData.blog[0].postTags} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="bocky." />
                    <meta property="og:image" content="../../../public/bocky.png" />
            </Helmet>
                <Navbar type="orange"/>
                <div className='blogContainer'>
                <div className="headerBlog">
                    <h2>{blogData.name}</h2>
                    <div>Read on how this project has impact on the company</div>
                    <div className='scrolldown2'>
                    <a href='#second' className='scrolldownText smnone'>Scroll down</a>
                    <div class="line"></div>
                    </div>
                </div>
    
                    <div className="row">
                    <div className="leftcolumnBlog">
                    {blogData.blog[0].articles.map((article) => (
                    
                        <div className="cardBlog">
                        <h2>{article.title}</h2>
                        <h5>Publish date: {article.date}</h5>
                        {article.imgurl === "" ? null : (
                             <div className="cardImg">
                             {loadingImage ? (
                                console.log('loading'),
                                 <div className="loading-shimmer"></div>
                             ) : (
                                    console.log('loaded'),
                                 <img src={require(`../img/detailprojectimages/${article.imgurl}`)} alt={article.altTag} />
                             )}
                             </div>
                        )    
                        }
                        <div className='textwrapper'>
                            {article.text.map((text) => (
                                <p className='articletext' dangerouslySetInnerHTML={{__html: text.paragraph}}></p>
                            ))}
                        </div>
                        </div>
                      
                    ))
                   
                    }
                     <div className="blogtags">
                        {blogData.blog[0].postTags.map((tag) => (
                            <div className='tag'>{tag}</div>
                        ))}
                    </div>
                    {blogData.linkfromweb === "" ? null : (
                        <Link rel="noreferrer" target={'_blank'} to={blogData.linkfromweb} className='checkbtnModal ' >
                        Check it out
                        </Link>
                    )}
                  
                       
                    </div>
                    <div className="rightcolumnBlog">
                        <div className="cardRightBlog">
                            <h2>Read more</h2>
                            { data.projects.map((project) => (
                                <Link rel="noreferrer" to={`/projects/${project.linkname}`} className='projectlink'>{project.name}</Link>
                            ))}
                            <p>Check all our projects out!</p>
                        </div>
                        <div className="cardRightBlog">
                            <h3>Follow us</h3>
                            <div className="socials notop">
                            <Link rel="noreferrer" target={'_blank'} to="https://www.instagram.com/bocky.be/"><BsInstagram className='iconSocial'/></Link>
                            <Link rel="noreferrer" target={'_blank'} to="https://discord.gg/QzjJ5dV2NX"><BsDiscord className='iconSocial'/></Link>
                            <Link rel="noreferrer" target={'_blank'} to="https://twitter.com/bocky_be"><BsTwitter className='iconSocial'/></Link>
    
                    </div>
                        </div>
                    </div>
                    </div>
    
                </div>
                <Footer/>
            </>
        )
      }


    
}

export default Detail